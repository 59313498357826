<template>
  <div class="body">
    <div class="login">
      <p>制度体系智能平台</p>
      <div class="input_Box" :class="{ activeClass: inpIndex == 1 }">
        <img
          style="position: absolute; left: 70px; top: 19px; z-index: 16"
          :src="[
            inpIndex == 1
              ? require('../../assets/images/img1/login11.png')
              : require('../../assets/images/img1/login1.png'),
          ]"
        />

        <el-input
          type="text"
          placeholder="请输入账号"
          @focus="clickInput(1)"
          v-model="userNo"
        >
        </el-input>
      </div>
      <div class="input_Box" :class="{ activeClass: inpIndex == 2 }">
        <img
          style="position: absolute; left: 70px; top: 19px; z-index: 16"
          :src="[
            inpIndex == 2
              ? require('../../assets/images/img1/login22.png')
              : require('../../assets/images/img1/login2.png'),
          ]"
        />
        <el-input
          type="password"
          placeholder="请输入密码"
          @focus="clickInput(2)"
          v-model="passWord"
        >
        </el-input>
      </div>
      <div class="input_Box" :class="{ activeClass: inpIndex == 3 }">
        <img
          style="position: absolute; left: 70px; top: 19px; z-index: 16"
          :src="[
            inpIndex == 3
              ? require('../../assets/images/img1/login33.png')
              : require('../../assets/images/img1/login3.png'),
          ]"
        />
        <el-input
          type="text"
          placeholder="请输入验证码"
          @focus="clickInput(3)"
          v-model="code"
          maxlength="4"
        >
        </el-input>
        <img :src="imgUrl" alt="" class="code-img" @click="getCode" />
      </div>
      <div class="bottom" @click="login">登录</div>
      <div class="other"><span>忘记密码？</span><span>注册账号</span></div>
    </div>
    <div class="bottom-box">
      <div>
        <img src="../../assets/images/img1/cname.png" alt="" />
        <span>景翔信息科技有限公司</span
        ><a href="http://beian.miit.gov.cn" target="_blank"
          >(粤ICP备2021114949号)</a
        >
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>{{ this.userNo }} | {{ this.passWord }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <PageDialog
      :dialogIndex="dialogIndex"
      @handleClose="handleClose"
      :showDialog="showDialog"
    ></PageDialog>
  </div>
</template>

<script>
import PageDialog from '../../components/dialog/PageDialog.vue'
import { get, getUserCode } from '../../api/http'
export default {
  components: {
    PageDialog,
  },
  data() {
    return {
      inpIndex: 0,
      imgUrl: '',
      guid: '',
      code: '',
      passWord: '',
      userNo: '',
      username: '',
      userno: '',
      dialogVisible: false,
      formLabelAlign: {},
      showDialog: false,
      dialogIndex: 2,
    }
  },
  mounted() {
    if (this.$route.query.userno && this.$route.query.username) {
      this.username = this.$route.query.username
      this.userno = this.$route.query.userno
      //console.log(this.userno, '*', this.username)
      // let url = '/api/DoLogin/DoLoginByNo?UserNo='
      let url = '/api/DoLogin/DoNoPwdLogin?UserNo=' + this.userno + '&UserName=' + this.username +'&skip=1'
      this.NoPwdlogin(url)
      // this.getCode()
    }
    this.getCode()
  },
  methods: {
    handleClose() {
      this.dialogIndex = 0
      this.showDialog = false
      this.$router.push('./page')
    },
    clickInput(i) {
      this.inpIndex = i
    },
    NoPwdlogin(url) {
      console.log(url)
      console.log(this.userno, this.username)
      get(
        url 
      )
        .then((resp) => {
          console.log(resp)
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: resp.message,
            })
            sessionStorage.setItem('userInfo', JSON.stringify(resp.data))
            sessionStorage.setItem('token', JSON.stringify(resp.data.userToken))
            // this.$router.push('./page')
            //this.getCompanyList()
            if(this.$route.query.skip == 1){
              this.getCompanyList(false)
              this.$router.push('/assignStudy')
            }else{
              this.getCompanyList(true)
            }
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('登录失败')
        })
    },
    getCode() {
      getUserCode('/api/UserCode')
        .then((resp) => {
          if (resp.status == 200) {
            this.imgUrl = window.URL.createObjectURL(resp.data)
            console.log(this.imgUrl)
            this.guid = resp.headers['content-type'].split('; ')[1]
          } else {
            this.$message.error('获取失败')
          }
        })
        .catch(() => {
          this.$message.error('获取失败')
        })
    },
    login() {
      if(!this.userNo || !this.passWord || !this.code){
        this.$message.warning('请输入完整信息')
        return
      }
      if(!this.guid){
        this.$message.warning('验证码过期，请刷新验证码')
        return
      }
      get(
        '/api/DoLogin/DoLogin?UserNo=' +
          this.userNo +
          '&PassWord=' +
          this.passWord +
          '&UserCode=' +
          this.code +
          '&GuId=' +
          this.guid
      )
        .then((resp) => {
          if (resp.code == 200 || resp.code == 301) {
            sessionStorage.setItem('userInfo', JSON.stringify(resp.data))
            sessionStorage.setItem('token', JSON.stringify(resp.data.userToken))
            this.getCompanyList(resp.code == 200 ? true : false)
          }
          if (resp.code == 200) {
            this.$message({ 
              type: 'success',
              message: '登录成功',
            })
          } else if (resp.code == 301) {
            this.showDialog = true
            this.dialogIndex = 2
          } else {
            {
              this.$message.error(resp.message)
              this.code = ''
              this.getCode()
            }
          }
        })
        .catch(() => {
          this.$message.error('系统异常，请稍后再试')
        })
    },
    // //将公司数据存在vuex，所有页面不再单独调用公司请求
    async getCompanyList(toPage) {
      await get('/api/Company/GetAllByUser')
        .then((resp) => {
          if (resp.code == 200) {
            this.$store.dispatch('companyUserList', resp.data)
            sessionStorage.setItem('companyUserList', JSON.stringify(resp.data))
          } else {
            this.$message.error('获取公司失败,请重新登录')
          }
        })
        .catch(() => {
          this.$message.error('系统异常,获取公司失败')
        })
      await get('/api/Company/GetAll')
        .then((resp) => {
          if (resp.code == 200) {
            this.$store.dispatch('companyList', resp.data)
            sessionStorage.setItem('companyList', JSON.stringify(resp.data))
          } else {
            this.$message.error('获取公司失败,请重新登录')
          }
        })
        .catch(() => {
          this.$message.error('系统异常,获取公司失败')
        })
      if (toPage) this.$router.push('./page')
    },
  },
}
</script>

<style lang="less" scoped>
.body {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  background-image: url('../../assets/background.jpg');
  background-size: 100%;
}
.opacity {
  width: 35%;
  height: 50%;
  position: absolute;
  top: 15%;
  left: 32%;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  box-shadow: 0px 9px 11px 2px rgba(109, 124, 134, 0.1);
}
.login {
  z-index: 10;
  margin-top: 120px;
  display: inline-block;
  text-align: start;
  width: 400px;
  background: #ffffff;
  border: 2px solid #d5d5d5;
  box-shadow: 0px 9px 11px 2px rgba(109, 124, 134, 0.1);
  > p {
    font-size: 30px;
    font-weight: bold;
    color: #117ef4;
    text-align: center;
    padding-bottom: 20px;
    letter-spacing: 5px;
    margin-bottom: 25px;
    margin-top: 20px;
    text-shadow: 0px 9px 42px rgba(121, 168, 226, 0.09);
  }
  img {
    width: 16px;
    height: 16px;
    z-index: 10;
    margin-right: 40px;
  }
  input {
    width: 250px;
    border: none;
    outline: none;
    padding-left: 5px;
    background-color: rgba(255, 255, 255, 0) !important;
    color: #000000;
    z-index: 10;
  }
  .code {
    position: relative;
    z-index: 10;
    input {
      z-index: 10;
      width: 170px;
    }
    .code-img {
      position: absolute;
      width: 80px;
      margin-left: 10px;
      height: 30px;
      right: 0;
      top: -10px;
      z-index: 10;
    }
  }
  & /deep/.el-input__inner {
    padding: 0 30px !important;
  }
}
.login div div {
  margin-top: 38px;
  z-index: 10;
  border-bottom: 2px solid #dddddd;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.el-input {
  border: 0px !important;
  margin: 0px !important;

  height: 50px !important ;
  width: 60%;
  transform: scale(1.2);
}

.input_Box {
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .code-img {
    position: absolute;
    width: 80px;
    margin-left: 0px;
    height: 30px;
    right: 20px;
    top: 10px;
    z-index: 10;
    border: 1px solid #d5d5d5;
  }
}
.bottom {
  width: 70%;
  font-size: 14px;
  margin-top: 20px;
  height: 40px;
  z-index: 10;
  margin-left: 15%;
  background: linear-gradient(0deg, #0b6ae8 0%, #178ffe 100%);
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  letter-spacing: 2px;
}
.other {
  text-align: center;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
  & span:nth-of-type(1) {
    color: rgb(117, 117, 117);
    margin-right: 5px;
  }
  & span:nth-of-type(2) {
    color: #178efe;
  }
}
.bottom-box {
  position: absolute;
  text-align: center;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  color: black;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    letter-spacing: 5px;
    margin-left: 170px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
}
.activeClass {
  border-color: #178efe !important;
}
</style>
