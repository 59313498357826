<template>
  <div>
    <Dialog
      :title="title1"
      v-if="dialogIndex == 1"
      @handleClose="handleClose"
      width="30%"
      top="12vh"
      :showDialog="showDialog"
    >
      <el-table
        :data="oaList"
        style="width: 100%"
        :header-cell-style="{ background: '#EDF0F7', color: '#5C5C5C' }"
        height="400"
      >
        <el-table-column prop="date" label="同步对象"> </el-table-column>
        <el-table-column prop="name" label="上次同步时间"> </el-table-column>
        <el-table-column prop="address" label="立即同步"> </el-table-column>
      </el-table>
    </Dialog>
    <Dialog
      :title="title2"
      v-if="dialogIndex == 2"
      @handleClose="handleClose"
      width="18%"
      top="24vh"
      :showTowText="true"
      @handleReqData="handleReqData"
      :showDialog="showDialog"
    >
      <div class="_Inp">
        <span style="width: 70px">旧密码：</span>
        <el-input type="password" placeholder="请输入旧密码" v-model="OldPwd"> </el-input>
      </div>
      <div class="_Inp">
        <span style="width: 70px">新密码：</span>
        <el-input type="password" placeholder="请输入新密码" v-model="NewPwd" />
        <img src="../../assets/images/img1/home1.png" alt="" />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { put } from '../../api/http'
import Dialog from '../dialog/Dialog.vue'
export default {
  props: ['dialogIndex', 'showDialog'],
  components: {
    Dialog,
  },
  data() {
    return {
      index: 0,
      // oa同步组织结构
      title1: '同步OA组织结构',
      oaList: [],
      // 更改密码
      title2: '更改登录密码',
      input1: '',
      OldPwd: '',
      NewPwd: '',
    }
  },

  mounted() {},
  methods: {
    handleClose() {
      this.$emit('handleClose')
    },
    handleReqData() {
      if (this._props.dialogIndex == 2) {
        this.changePwd()
      }
    },
    changePwd() {
      if (this.OldPwd == '' || this.NewPwd == '') {
        this.$message({
          type: 'warning',
          message: '请填写完整信息',
        })
        return
      }
      // if (
      //   !/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W]).{8,}/.test(this.NewPwd)
      // ) {
      //   this.$message({
      //     type: 'warning',
      //     message: '密码必须8位以上，且含有大小写字母、字符、数字',
      //     duration: 5000,
      //   })
      //   return
      // }
      put(
        '/api/DoLogin/UpdatePwd?id=' +
          JSON.parse(sessionStorage.getItem('userInfo')).id +
          '&OldPwd=' +
          this.OldPwd +
          '&NewPwd=' +
          this.NewPwd
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '修改成功',
            })
            sessionStorage.setItem('userInfo', JSON.stringify(resp.data))
            this.OldPwd = ''
            this.NewPwd = ''
            this.handleClose()
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('修改失败')
        })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-table .el-table__cell {
  text-align: center;
}
._Inp > img {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 10px;
}
</style>
